body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Figtree', 'Nunito',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: #F3F3F3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sd-root-modern {
  background-color: white !important;
}
@font-face{
  font-family: "SuisseIntl-Bold";
  src: url("assets/fonts/SuisseIntl-Bold/d1a580023d40c546276decde1c711e60.eot");
  src: url("assets/fonts/SuisseIntl-Bold/d1a580023d40c546276decde1c711e60.eot?#iefix")format("embedded-opentype"),
      url("assets/fonts/SuisseIntl-Bold/d1a580023d40c546276decde1c711e60.woff")format("woff"),
      url("assets/fonts/SuisseIntl-Bold/d1a580023d40c546276decde1c711e60.woff2")format("woff2"),
      url("assets/fonts/SuisseIntl-Bold/d1a580023d40c546276decde1c711e60.ttf")format("truetype"),
      url("assets/fonts/SuisseIntl-Bold/d1a580023d40c546276decde1c711e60.svg#SuisseIntl-Bold")format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}

@font-face{
  font-family: "SuisseIntl-Light";
  src: url("assets/fonts/SuisseIntl-Light/03d5b20d124cd26dc873bd4a8e42313e.eot");
  src: url("assets/fonts/SuisseIntl-Light/03d5b20d124cd26dc873bd4a8e42313e.eot?#iefix")format("embedded-opentype"),
      url("assets/fonts/SuisseIntl-Light/03d5b20d124cd26dc873bd4a8e42313e.woff")format("woff"),
      url("assets/fonts/SuisseIntl-Light/03d5b20d124cd26dc873bd4a8e42313e.woff2")format("woff2"),
      url("assets/fonts/SuisseIntl-Light/03d5b20d124cd26dc873bd4a8e42313e.ttf")format("truetype"),
      url("assets/fonts/SuisseIntl-Light/03d5b20d124cd26dc873bd4a8e42313e.svg#SuisseIntl-Light")format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}

@font-face{
  font-family: "SuisseIntl-Medium";
  src: url("assets/fonts/SuisseIntl-Medium/c446362802681bacaacbad0f39bfc1a5.eot");
  src: url("assets/fonts/SuisseIntl-Medium/c446362802681bacaacbad0f39bfc1a5.eot?#iefix")format("embedded-opentype"),
      url("assets/fonts/SuisseIntl-Medium/c446362802681bacaacbad0f39bfc1a5.woff")format("woff"),
      url("assets/fonts/SuisseIntl-Medium/c446362802681bacaacbad0f39bfc1a5.woff2")format("woff2"),
      url("assets/fonts/SuisseIntl-Medium/c446362802681bacaacbad0f39bfc1a5.ttf")format("truetype"),
      url("assets/fonts/SuisseIntl-Medium/c446362802681bacaacbad0f39bfc1a5.svg#SuisseIntl-Medium")format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}

@font-face{
  font-family: "SuisseIntl-Regular";
  src: url("assets/fonts/SuisseIntl-Regular/88f10bf18a36407ef36bf30bc25a3618.eot");
  src: url("assets/fonts/SuisseIntl-Regular/88f10bf18a36407ef36bf30bc25a3618.eot?#iefix")format("embedded-opentype"),
      url("assets/fonts/SuisseIntl-Regular/88f10bf18a36407ef36bf30bc25a3618.woff")format("woff"),
      url("assets/fonts/SuisseIntl-Regular/88f10bf18a36407ef36bf30bc25a3618.woff2")format("woff2"),
      url("assets/fonts/SuisseIntl-Regular/88f10bf18a36407ef36bf30bc25a3618.ttf")format("truetype"),
      url("assets/fonts/SuisseIntl-Regular/88f10bf18a36407ef36bf30bc25a3618.svg#SuisseIntl-Regular")format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}

@font-face{
  font-family: "SuisseIntl-SemiBold";
  src: url("assets/fonts/SuisseIntl-SemiBold/653d9381828e9577fb1e417dc047f89d.eot");
  src: url("assets/fonts/SuisseIntl-SemiBold/653d9381828e9577fb1e417dc047f89d.eot?#iefix")format("embedded-opentype"),
      url("assets/fonts/SuisseIntl-SemiBold/653d9381828e9577fb1e417dc047f89d.woff")format("woff"),
      url("assets/fonts/SuisseIntl-SemiBold/653d9381828e9577fb1e417dc047f89d.woff2")format("woff2"),
      url("assets/fonts/SuisseIntl-SemiBold/653d9381828e9577fb1e417dc047f89d.ttf")format("truetype"),
      url("assets/fonts/SuisseIntl-SemiBold/653d9381828e9577fb1e417dc047f89d.svg#SuisseIntl-SemiBold")format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}
